import React, { useEffect, useState, useCallback } from 'react';
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { UserContext } from "../context/AuthContext";

function BatteryStatus({ facility }) {
  const MIN_BATTERY_LEVEL = 20

  let battery;

  const userContext = React.useContext(UserContext);

  const [showModal, setShowModal] = useState(false);
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [charging, setCharging] = useState(null);

  const updateBatteryStatus = useCallback(() => {
    async function createOnDB(level, charging) {
      await addDoc(collection(db, "DevicesBatteryAlerts"), {
        charging: charging,
        createdAt: Timestamp.fromDate(new Date()),
        facilityTitle: facility.title,
        level: level,
        userAgent: navigator.userAgent,
        userUID: userContext.user.uid,
      });
    }

    setBatteryLevel((battery.level * 100).toFixed(0));
    setCharging(battery.charging);

    if (!battery.charging && (battery.level * 100) <= MIN_BATTERY_LEVEL) {
      setShowModal(true);
      createOnDB(battery.level, battery.charging);
    } else {
      setShowModal(false);
    }
  }, [battery, userContext, facility]);

  useEffect(() => {
    if ('getBattery' in navigator) {
      navigator.getBattery().then(function (bat) {
        battery = bat;

        battery.addEventListener('chargingchange', updateBatteryStatus);
        battery.addEventListener('levelchange', updateBatteryStatus);

        // Inicializar y mostrar el estado de la batería
        updateBatteryStatus();
      });
    } else {
      console.error('Battery Status API not supported in this browser.');
    }

    return () => {
      if (battery) {
        battery.removeEventListener('chargingchange', updateBatteryStatus);
        battery.removeEventListener('levelchange', updateBatteryStatus);
      }
    };
  }, [updateBatteryStatus]);

  function batteryData(size = "w-5 h-5", font = "text-xs") {
    return (
      <div className={`flex items-center ${font} ${charging ? 'text-green-500' : 'text-red-600'}`}>
        {charging ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={size}>
            <path d="M4.75 8a.75.75 0 0 0-.75.75v2.5c0 .414.336.75.75.75h9.5a.75.75 0 0 0 .75-.75v-2.5a.75.75 0 0 0-.75-.75h-9.5Z" />
            <path fillRule="evenodd" d="M1 7.25A2.25 2.25 0 0 1 3.25 5h12.5A2.25 2.25 0 0 1 18 7.25v1.085a1.5 1.5 0 0 1 1 1.415v.5a1.5 1.5 0 0 1-1 1.415v1.085A2.25 2.25 0 0 1 15.75 15H3.25A2.25 2.25 0 0 1 1 12.75v-5.5Zm2.25-.75a.75.75 0 0 0-.75.75v5.5c0 .414.336.75.75.75h12.5a.75.75 0 0 0 .75-.75v-5.5a.75.75 0 0 0-.75-.75H3.25Z" clipRule="evenodd" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={size}>
            <path d="M4.75 8a.75.75 0 0 0-.75.75v2.5c0 .414.336.75.75.75H9.5a.75.75 0 0 0 .75-.75v-2.5A.75.75 0 0 0 9.5 8H4.75Z" />
            <path fillRule="evenodd" d="M3.25 5A2.25 2.25 0 0 0 1 7.25v5.5A2.25 2.25 0 0 0 3.25 15h12.5A2.25 2.25 0 0 0 18 12.75v-1.085a1.5 1.5 0 0 0 1-1.415v-.5a1.5 1.5 0 0 0-1-1.415V7.25A2.25 2.25 0 0 0 15.75 5H3.25ZM2.5 7.25a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-.75.75H3.25a.75.75 0 0 1-.75-.75v-5.5Z" clipRule="evenodd" />
          </svg>
        )}
        <div className='ml-1'>{batteryLevel}%</div>
      </div>
    )
  }

  return (
    <>
      {batteryData()}
      {showModal && (
        <div className="fixed inset-0 overflow-y-auto flex items-start justify-center h-screen w-screen">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="relative px-6 py-8 bg-white rounded-md w-80 mt-14">
            <button
              type="button"
              className="absolute top-4 right-4 text-gray-600"
              onClick={() => setShowModal(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="text-center">
              <div className='flex justify-center mb-2 animate-pulse'>
                {batteryData("w-10 h-10", "text-lg")}
              </div>
              <div className='text-gray-600 mb-4'>Batería baja. Por favor conecta el dispositivo a la corriente.</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BatteryStatus;
