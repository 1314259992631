import React from "react";
import { dbTest } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
} from "firebase/firestore";
import DeviceForm from "./DeviceForm";
import { isOnline } from "../helpers";

export default function DiagnosisDevices() {
  const [devices, setDevices] = React.useState();


  React.useEffect(() => {
    async function retrieveUsers() {
      const usersRef = collection(dbTest, `Dispositivos`);
      const q = query(usersRef);

      onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map(d => {
          return {
            ...d.data(),
            id: d.id,
            isOnline: isOnline(d?.data().Datetime.seconds),
          }
        })
        setDevices(docs)
      })
    }
    retrieveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="bg-white mb-4 py-2 rounded-xl shadow-sm mb-8">
        <div className="text-xl mb-2 uppercase text-gray-400 py-2 px-4 font-black">Devices</div>
        {devices && devices.map(d => (
          <div
            key={d.id}
            className="px-4 flex py-3 justify-between border-b border-gray-200 cursor-pointer hover:bg-gray-50"
          >
            <div className="text-gray-600 font-bold">
              {d.id}
            </div>

            <div className="flex items-center">
              <span className={`text-sm mr-2 font-bold ${d?.isOnline ? 'text-green-500' : 'text-red-500'}`}>{d?.isOnline ? "Up" : "Down"}</span>
              <span className="relative flex h-3 w-3">
                <span className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${d?.isOnline ? 'bg-green-400' : 'bg-red-400'}`}></span>
                <span className={`relative inline-flex rounded-full h-3 w-3 ${d?.isOnline ? 'bg-green-500' : 'bg-red-500'}`}></span>
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white mb-4 py-2 rounded-xl shadow-sm">
        <div className="text-xl mb-2 uppercase text-gray-400 py-2 px-4 font-black">
          Add a device
        </div>
        <div className="px-4 mb-8">
          <DeviceForm />
        </div>
      </div>
    </div>
  )
}
