import React, { useEffect } from "react";
import { getTime, fullReasonsForResolutionList } from "./helpers"

const getZoneBgColor = (timeInSeconds) => {
  return timeInSeconds <= 21 && timeInSeconds >= 0
    ? {
      backgroundColor: "bg-red-700",
      opacity: "opacity-100",
      textColor: "text-white",
      borderColor: "",
    }
    : timeInSeconds <= 300 && timeInSeconds > 21
      ? {
        backgroundColor: "bg-red-600",
        opacity: "opacity-75",
        textColor: "text-white",
        borderColor: "",
      }
      : timeInSeconds <= 600 && timeInSeconds > 300
        ? {
          backgroundColor: "bg-red-500",
          opacity: "opacity-50",
          textColor: "text-white",
          borderColor: "",
        }
        : timeInSeconds <= 900 && timeInSeconds > 600
          ? {
            backgroundColor: "bg-red-400",
            opacity: "opacity-25",
            textColor: "text-gray-700",
            borderColor: "border-b",
          }
          : {
            backgroundColor: "bg-white hover:bg-gray-50",
            opacity: "opacity-0",
            textColor: "text-gray-700",
            borderColor: "border-b",
          };
};

const AlarmItem = ({ zoneName, date, id, setZones, onResolve, alarmObj }) => {
  const [time, setTime] = React.useState(date);

  React.useEffect(() => {
    const updateTime = setTimeout(() => {
      setTime(time + 1);
    }, 1000);

    return () => clearTimeout(updateTime);
  }, [time, date]);

  useEffect(() => {
    setZones((prevZonesOfSDM) =>
      prevZonesOfSDM.map((z) =>
        z.id === id ? { ...z, opacity: getZoneBgColor(time).opacity } : z
      )
    );
    setTime(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time]);

  return (
    <div
      onClick={() => onResolve(alarmObj)}
      className={`w-full cursor-pointer transition ${getZoneBgColor(time).backgroundColor
        } px-5 py-4 ${getZoneBgColor(time).borderColor} ${getZoneBgColor(time).textColor
        }`}
    >
      <div className="flex justify-between items-center">
        <div>
          <div className="flex items-center mb-2">
            <h5 className="font-bold ">{zoneName}</h5>
            <span className="opacity-30 font-black mx-1">•</span>
            <p className="mr-4 opacity-60">
              {time >= 0
                ? `Hace ${getTime(Math.abs(time))}`
                : `starts in ${getTime(Math.abs(time))}`}
            </p>
          </div>
          <div className="flex opacity-60 text-sm items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            {`${alarmObj.createdAtLocalTime} - Duración ${getTime(alarmObj.duration)}`}
          </div>
        </div>
        <div className="flex items-center">


          {alarmObj.resolvedAt ? (
            <div className="flex items-center">
              <span className="opacity-40 text-xs mr-1">
                {
                  fullReasonsForResolutionList.find(i => i.value === alarmObj.reasonForResolution)?.label
                }
              </span>
              {fullReasonsForResolutionList.find(i => i.value === alarmObj.reasonForResolution)?.value === "resolved_automatically" ? (
                <i className="text-yellow-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                  </svg>
                </i>
              ) : (
                  <i className="text-green-600">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                    </svg>
                  </i>
              )}

            </div>
          ) : (
              <span className="btn-outlined text-xs text-inherit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline-block mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
                <span className="pr-1 text-inherit ">Resolver</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlarmItem;
