import React from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import Loading from "./Loading";
import { UserContext } from "../context/AuthContext";

function Facility({ facility }) {
  const facilityUrl = `/facilities/${facility.id}`

  return (
    <Link
      className="shadow cursor-pointer hover:shadow-lg transition-all rounded-lg"
      to={facilityUrl}
    >
      <div className="h-60 w-full rounded-tl-lg rounded-tr-lg overflow-hidden">
        <img src={require(`../assets/${facility.image}`)} alt={facility.title} className="h-full w-full object-cover" />
      </div>
      <div className="bg-white border-x border-b p-4 p rounded-bl-lg rounded-br-lg font-bold">
        <h4>{facility.title}</h4>
      </div>
    </Link>
  );
}

const Facilities = () => {
  const [facilities, setFacilities] = React.useState(null)
  const userContext = React.useContext(UserContext)

  React.useEffect(() => {
    async function retrieveFacilities() {
      const facilitiesRef = collection(db, `Facilities`);
      const q = query(facilitiesRef, where("userUID", "==", userContext.user.uid));

      onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map(d => ({ ...d.data(), id: d.id }))
        setFacilities(docs)
      })
    }
    if (facilities === null) {
      retrieveFacilities();
    }
  }, [userContext])

  return (
    <div className="max-w-[1100px] mt-16 mx-auto px-8">
      <div className="flex-1">
          <BreadCrumbs pages={[]}/>
        <div className="grid gap-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
          {facilities ? facilities.map((facility) => (
            <Facility facility={facility} key={facility.id} />
          )) : <div className="flex justify-center items-center py-20"> <Loading /></div>}
        </div>
      </div>
    </div>
  );
};

export default Facilities;
