import React, { useEffect, useState } from "react";
import moment from "moment";
import AlarmItem from "./AlarmItem";
import CloseAlarmModal from "./CloseAlarmModal";
import { ALARM_LIMIT_IN_SEC } from "./helpers"
import { UserAuth } from "../context/AuthContext";
import BatteryStatus from "./BatteryStatus";
import Clock from "./Clock";

export default function MapWithList({ title, alarms, facility, zones }) {
  const { logout } = UserAuth();

  const [showModal, setShowModal] = React.useState(false);
  const [selectedAlarm, setSelectedAlarm] = React.useState(null);
  const [sortedAlarms, setSortedAlarms] = React.useState(
    alarms
      .sort((a, b) => new Date(b.date) - new Date(a.date))
  );

  function getZoneOpacity(zoneID) {
    const currentDate = new Date();
    const filteredAlarms = alarms.filter(z => z.zoneID === zoneID)

    if (!filteredAlarms?.length) return "opacity-0"

    const recentAlarms =
      filteredAlarms
        .filter(z => ((currentDate / 1000) - z.updatedAt.seconds) < ALARM_LIMIT_IN_SEC)
        .map(z => z.zoneID)

    if (recentAlarms.includes(zoneID)) return "opacity-1"
    return "opacity-0"
  }

  function onOpenModal(docID) {
    setShowModal(true);
    setSelectedAlarm(docID)
  }

  function differenceInSeconds(date) {
    const date1 = moment(date);
    const date2 = moment(new Date());
    return date2.diff(date1, "seconds");
  }

  useEffect(() => {
    setSortedAlarms(alarms);
  }, [alarms]);

  return (
    <div className="flex flex-col lg:flex-row overflow-hidden lg:h-screen items-center">
      <div className="relative">
        {!facility.noMap && (
          <>
            <img alt={title} src={require(`../assets/${facility.image}`)} className="h-full w-full" />
            {zones.map((zone) => (
              <div className="absolute inset-0" key={zone.id}>
                <img
                  alt="1"
                  src={require(`../assets/${zone.img}.png`)}
                  className={`h-full w-full transition-opacity ${getZoneOpacity(zone.id)}`}
                />
              </div>
            ))}
          </>
        )}
      </div>

      <div className="bg-white pb-4 flex grow flex-col h-auto lg:h-full lg:overflow-y-auto min-w-full lg:min-w-[400px] lg:max-w-[500px]">
        <div className="mb-2 px-5 bg-gray-100 flex justify-center items-center">
          <Clock />
          <BatteryStatus facility={facility} />
        </div>
        <h4 className="text-2xl px-5 my-2 font-bold text-blue-400 flex">
          <svg onClick={logout} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
          </svg>
          <span>
            {facility.title}
            {facility.visibleHoursRange !== undefined && (
              <span className="block font-normal text-sm text-gray-400">{`Ataques realizados entre las ${facility.visibleHoursRange[0]}hrs y las ${facility.visibleHoursRange[1]}hrs`}</span>
            )}
          </span>
        </h4>

        {sortedAlarms.length > 0 ? (
          sortedAlarms.map((alarm, index) => (
            <AlarmItem
              zoneName={alarm.zoneName}
              date={differenceInSeconds(alarm.date)}
              id={alarm.id}
              zones={sortedAlarms}
              setZones={setSortedAlarms}
              onResolve={onOpenModal}
              alarmObj={alarm}
              key={index}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-full py-10">
            <div className="text-center text-xl font-bold text-gray-400 max-w-[300px]">
              <div className="text-6xl mb-2">👮</div>
              No se han registrado ataques en las últimas horas.
            </div>
          </div>
        )}
      </div>
      {showModal && (
        <CloseAlarmModal
          alarmObj={selectedAlarm}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};
