// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore} from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyACXNohUE88qpIw2C-edloNklO7Vw-EamA",
  authDomain: "tank-59802.firebaseapp.com",
  databaseURL: "https://tank-59802-default-rtdb.firebaseio.com",
  projectId: "tank-59802",
  storageBucket: "tank-59802.appspot.com",
  messagingSenderId: "210895005986",
  appId: "1:210895005986:web:20b88f1d81442cff219d7b"
};

const firebaseConfigTest = {
  apiKey: "AIzaSyAFLTokNQmF6tCxfpBcJNAm4CWq8GsHOwQ",
  authDomain: "test-d0c3a.firebaseapp.com",
  databaseURL: "https://test-d0c3a-default-rtdb.firebaseio.com",
  projectId: "test-d0c3a",
  storageBucket: "test-d0c3a.appspot.com",
  messagingSenderId: "322028856098",
  appId: "1:322028856098:web:789497012ccec05fbefb7b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appTest = initializeApp(firebaseConfigTest, 'test');
export const auth = getAuth(app);
export const db = getFirestore(app);
export const dbTest = getFirestore(appTest);
export default app;

