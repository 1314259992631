import React, { useState } from 'react';
import { dbTest } from "../../firebase";
import { doc, setDoc } from 'firebase/firestore'; // Import firestore module

export default function DeviceForm() {
  const initialValue = { name: '', id: '' }
  const [formData, setFormData] = useState(initialValue);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formDataFiltered = Object.fromEntries(
    Object.entries(formData).filter(([key]) => key !== 'id')
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(dbTest, "Dispositivos", formData.id), formDataFiltered);
      setFormData(initialValue);
      alert('Document created successfully!');
    } catch (error) {
      console.error('Error creating document: ', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label className='mb-4 block'>
        Name:
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="input-text"
        />
      </label>

      <label className='mb-4 block'>
        ID:
        <input
          type="text"
          name="id"
          value={formData.id}
          onChange={handleChange}
          className="input-text"
        />
      </label>

      <button type="submit" className='btn'>Submit</button>
    </form>
  );
};
