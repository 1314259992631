import React from "react";
import { UserContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import Loading from "./Loading";

export default function NavigateUserFacility({ children }) {
  const [facility, setFacility] = React.useState(null)
  const [fetchingFacility, setFetchingFacility] = React.useState(true)
  const userContext = React.useContext(UserContext)


  React.useEffect(() => {
    if (userContext.user?.uid) {
      async function retrieveFacilities() {
        const facilitiesRef = collection(db, `Facilities`);
        const q = query(facilitiesRef, where("userUID", "==", userContext.user.uid));

        onSnapshot(q, (snapshot) => {
          const docs = snapshot.docs.map(d => ({ ...d.data(), id: d.id }))
          setFacility(docs[0])
          setFetchingFacility(false)
        })
      }
      retrieveFacilities();
    }
  }, [userContext])

  if (fetchingFacility) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loading />
      </div>
    )
  }

  if (!facility?.id) return children

  return <Navigate to={`/facilities/${facility?.id}`} />;
}
