import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { firebaseAuthLoginError } from "../utils/firebase-auth-login-error";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signIn(email, password);
      navigate("/account");
    } catch (e) {
      setError(firebaseAuthLoginError(e.code));
    }
  };

  return (
    <div className="h-[100vh] flex items-center justify-center">
      <div className="max-w-[500px] flex-1 mx-auto my-16 p-14 border rounded-lg bg-white shadow-lg">
        <div>
          <h1 className="text-2xl font-bold mb-4">Sign in to your account</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-2">
            <label className="py-2 font-medium">Email Address</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="input-text"
              type="email"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="py-2 font-medium">Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="input-text"
              type="password"
              required
            />
          </div>
          {Boolean(error) && (
            <div className="p-4 rounded bg-red-300 flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-red-700"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>

              <p className="text-red-700">{error}</p>
            </div>
          )}
          <button className="btn mt-6 w-full text-lg">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signin;
