import React from "react";
import { db } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
} from "firebase/firestore";
import Zone from "./Zone";

export default function ZonesByFacility({ facility, selectZone }) {
  const [zones, setZones] = React.useState(null)
  React.useEffect(() => {
    async function retrieveZones() {
      const zonesRef = collection(db, `Facilities/${facility.id}/zones`);
      const q = query(zonesRef);

      onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map(d => ({ ...d.data(), id: d.id }))
        setZones(docs)
      })
    }
    if (zones === null) {
      retrieveZones();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="">
      <div>
        {zones && zones.map(z => (
          <div key={z.id} onClick={() => selectZone(z)}>
            <Zone zone={z} />
          </div>
        ))}
      </div>
    </div>
  )
}
