import React from "react";
import { db } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import Loading from "../Loading";
import CompanyOverview from "./CompanyOverview";
import moment from "moment";
import LogsModal from "./LogsModal";
import { UserContext } from "../../context/AuthContext";
import { UserAuth } from "../../context/AuthContext";
import DiagnosisDevices from "./DiagnosisDevices";

export default function AdminsHome() {
  const [users, setUsers] = React.useState(null)
  const [showModal, setShowModal] = React.useState(false)
  const [modalLoading, setModalLoading] = React.useState(true)
  const [zoneEvents, setZoneEvents] = React.useState(null)
  const [keepAliveLog, setKeepAliveLog] = React.useState(null)
  const [keepAliveDailyLog, setKeepAliveDailyLog] = React.useState(null)
  const [selectedZoneLogs, setSelectedZoneLogs] = React.useState(null)
  const userContext = React.useContext(UserContext)
  const { logout } = UserAuth();
  const [selectedTab, setSelectedTab] = React.useState('overview')

  React.useEffect(() => {
    async function retrieveUsers() {
      const usersRef = collection(db, `${userContext.user.uid}/CarDefenseDashboard/users`);
      const q = query(usersRef);

      onSnapshot(q, (snapshot) => {
        if (snapshot.docs?.length > 0) {
          const docs =
            snapshot.docs.map(d => ({ ...d.data(), id: d.id }))
          setUsers(docs)
        } else {
          logout()
        }
      })
    }
    if (users === null) {
      retrieveUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onSelectZone(zone) {
    setShowModal(true)
    setModalLoading(true)
    setSelectedZoneLogs(zone)

    async function retrieveKeepAliveLog() {
      const logsRef = collection(db, 'KeepAliveLog');
      const q = query(
        logsRef,
        where("DeviceID", "==", zone.id),
        orderBy("Datetime", "desc"),
        limit(24)
      )

      onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const docs = snapshot.docs.map(doc => (
            {
              ...doc.data(),
              Date: moment(doc.data().Datetime.seconds * 1000).format('DD/MM/YY LT'),
            }
          ))
          setKeepAliveLog(docs)
        }
      })
    }

    async function retrieveKeepAliveDailyLog() {
      const logsRef = collection(db, 'KeepAliveDailyLog');
      const q = query(
        logsRef,
        where("DeviceID", "==", zone.id),
        orderBy("Datetime", "desc"),
        limit(24)
      )

      onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const docs = snapshot.docs.map(doc => (
            {
              ...doc.data(),
              Date: moment(doc.data().Datetime.seconds * 1000).format('DD/MM/YY LT'),
            }
          ))
          setKeepAliveDailyLog(docs)
        }
      })
    }

    async function retrieveZoneEvents(path) {
      setModalLoading(true)
      const eventsRef = collection(db, path);
      const q = query(
        eventsRef,
        where("ID", "==", Number(zone.id)),
        orderBy("Datetime", "desc"),
        limit(6)
      )

      onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map(d => (
          {
            ...d.data(),
            id: d.id,
            Date: moment(d.data().Datetime.seconds * 1000).format('DD/MM/YY LT'),
          }
        ))
        if (docs?.length > 0) {
          setZoneEvents(docs)
          setModalLoading(false)
        }
      })
    }

    retrieveZoneEvents(`AvisosCopec`);
    retrieveZoneEvents(`Avisos`);
    retrieveZoneEvents(`Avisos2`);
    retrieveZoneEvents(`Avisos3`);
    retrieveKeepAliveLog();
    retrieveKeepAliveDailyLog();
  }

  return (
    <div className="max-w-[960px] mt-16 mx-auto px-8">
      <div>
        <div className="flex items-center mb-8 justify-between">
          <h1 className="text-4xl font-extrabold text-gray-900">
            Overview Cardefense
          </h1>

          <div>
            <button type="button" className="btn-outlined ml-2 text-xs" onClick={() => setSelectedTab('overview')}>Car defense</button>
            <button type="button" className="btn-outlined ml-2 text-xs" onClick={() => setSelectedTab('diagnocis')}>Diagnosis devices</button>
          </div>
        </div>

        {selectedTab === 'overview' && (
          <div>
            {users ? users.map((user) => (
              <CompanyOverview key={user.id} companyName={user.name} selectZone={onSelectZone} />
            )) : <div className="flex justify-center items-center py-20"> <Loading /></div>}
          </div>
        )}{
          selectedTab === 'diagnocis' && (
            <DiagnosisDevices />
          )
        }

      </div>
      <LogsModal
        showModal={showModal}
        setShowModal={setShowModal}
        zoneEvents={zoneEvents}
        modalLoading={modalLoading}
        selectedZoneLogs={selectedZoneLogs}
        keepAliveLog={keepAliveLog}
        keepAliveDailyLog={keepAliveDailyLog}
      />
    </div>
  )
}
