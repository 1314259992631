import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MapWithList from "./MapWithList";
import alert from "../assets/alert.mp3";
import moment from "moment";

import { db } from "../firebase";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import Loading from "./Loading";
import useInterval from "../hooks/useInterval";

import { ALARM_LIMIT_IN_SEC } from "./helpers"

function diffInSecondsFromNow(dateInSeconds) {
  const docDateInMilsec = dateInSeconds * 1000;
  const nowInMilsec = (new Date).getTime();
  return Math.floor((nowInMilsec - docDateInMilsec) / 1000);
}

const Facility = () => {
  const { id } = useParams();

  const [facility, setFacility] = useState(null)
  const [zones, setZones] = useState(null)
  const [facilityData, setFacilityData] = useState([]);
  const [loading, setLoading] = useState(true);

  const zonesIds = zones?.map(z => z.id) || []

  const resolveAlarm = () => {
    const secondsToClose = facility.secondsToCloseAlarm || ALARM_LIMIT_IN_SEC;
    const alarmsToBeClosed = facilityData.filter(d => !d.resolvedAt && diffInSecondsFromNow(d.createdAt.seconds) > secondsToClose);

    const updateDocument = async (id) => {
      const alarmRef = doc(db, "GroupedAlarms", id);
      const message = `🤖 Ataque cerrado automáticamente luego de ${secondsToClose / 60} min.`

      await updateDoc(alarmRef, {
        notes: message,
        reasonForResolution: "resolved_automatically",
        plate: "",
        resolvedAt: Timestamp.fromDate(new Date()),
      });
    }

    if (alarmsToBeClosed.length > 0 && facility.autoCloseAlarms) {
      alarmsToBeClosed.forEach(d => updateDocument(d.docID));
    }
  };
  useInterval(resolveAlarm, 60000);

  useEffect(() => {
    if (facility === null) {
      async function retrieveFacility() {
        const facilityRef = doc(db, `Facilities/${id}`);
        const q = query(facilityRef);

        onSnapshot(q, (snapshot) => {
          setFacility(snapshot.data())
        })
      }
      retrieveFacility();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (zones === null) {
      async function retrieveZones() {
        const zonesRef = collection(db, `Facilities/${id}/zones`);
        const q = query(zonesRef);

        onSnapshot(q, (snapshot) => {
          const docs = snapshot.docs.map(d => ({ ...d.data(), id: Number(d.id) }))
          setZones(docs)
        })
      }
      retrieveZones();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (zones !== null) {
      async function retriveAlarms() {
        const alarmsRef = collection(db, "GroupedAlarms");
        const q = query(
          alarmsRef,
          where("zoneID", "in", zonesIds),
          orderBy("updatedAt", "desc"),
          limit(50)
        );

        onSnapshot(q, (snapshot) => {
          const formattedAlarms =
            snapshot.docs.map(doc => {
              const lastDate =
                doc.data().resolvedAt ?
                  new Date(doc.data().resolvedAt.seconds * 1000) :
                  new Date()

              return {
                ...doc.data(),
                lastDate,
                resolvedAt: doc.data().resolvedAt,
                date: new Date(doc.data().updatedAt.seconds * 1000),
                zoneName: zones.find((f) => f.id === doc.data().zoneID).zoneName,
                docID: doc.id,
                createdAtLocalTime: moment(doc.data().createdAt.seconds * 1000).format('LT'),
                createdAtDate: new Date(doc.data().createdAt.seconds * 1000),
              }
            })

          const isAlarmInTime = alarmHour => {
            if (!facility?.visibleHoursRange) return true;
            const secondValue =
              facility.visibleHoursRange[1] < 12 ?
                (facility.visibleHoursRange[1] + 24) :
                facility.visibleHoursRange[1];
            return alarmHour > facility.visibleHoursRange[0] && alarmHour < secondValue;
          }

          const filteredFormattedAlarms =
            facility.visibleHoursRange === undefined ?
              formattedAlarms :
              formattedAlarms.filter(alarm => isAlarmInTime(alarm.createdAtDate.getHours()))

          setFacilityData(filteredFormattedAlarms)
          setLoading(false)

          snapshot.docChanges().forEach((change) => {
            const newAlarmCreatedAt = new Date(change.doc.data().createdAt.seconds * 1000)
            const isNewAllarm = diffInSecondsFromNow(change.doc.data().createdAt.seconds) < ALARM_LIMIT_IN_SEC;

            if (isNewAllarm && change.type === "added" && isAlarmInTime(newAlarmCreatedAt.getHours())) {
              new Audio(alert).play();
            }
          });
        });
      }
      retriveAlarms();
    }

  }, [zones]);

  useEffect(() => {
    if (zones !== null) {
      async function retriveRestarts() {
        const restartRef = collection(db, "WebAppRestarts");
        const q = query(
          restartRef,
          where("FacilityID", "==", id),
          orderBy("Datetime", "desc"),
          limit(1)
        );

        onSnapshot(q, (snapshot) => {

          snapshot.docChanges().forEach((change) => {
            const docDateInMilsec = change.doc.data().Datetime.seconds * 1000;
            const nowInMilsec = (new Date).getTime();
            const diffTimeInSec = (nowInMilsec - docDateInMilsec) / 1000;
            const isNewRestart = Math.floor(diffTimeInSec) < 4;
            if (isNewRestart && change.type === "added") {
              window.location.assign("/")
            }
          });
        });
      }
      retriveRestarts();
    }

  }, [zones]);

  if (!loading && !facility) return <Navigate to={`/`} />;

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <Loading size="14" />
    </div>
  )
  return (
    <div className="bg-white lg:bg-gray-900 min-w-screen min-h-screen">
      <div className="max-w-[1800px] mx-auto flex flex-col items-center">
        <MapWithList
          title={facility.title}
          alarms={facilityData}
          facility={facility}
          zones={zones}
        />
      </div>
    </div>
  );
};

export default Facility;
