import React from "react";
import Loading from "../Loading";
import moment from "moment";

function colorByPercentaje(percentaje) {
  if (percentaje > 90) return "bg-green-500"
  if (percentaje < 20) return "bg-red-500"
  return "bg-yellow-500"
}

export default function LogsModal({
  showModal,
  setShowModal,
  zoneEvents,
  modalLoading,
  selectedZoneLogs,
  keepAliveLog,
  keepAliveDailyLog,
}) {

  return (
    showModal && (
      <div className="fixed inset-0 overflow-y-auto flex items-center justify-center h-screen w-screen">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative px-8 py-10 bg-white rounded-md min-w-[500px]">
          <button
            type="button"
            className="absolute top-4 right-4 text-gray-600"
            onClick={() => setShowModal(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <h3 className="px-4 text-2xl mb-6 font-bold text-gray-600">{selectedZoneLogs.zoneName}</h3>
          <div className="px-4 mb-14">
            <div className="mb-6 text-gray-400 font-bold">{`Uptime últimas ${keepAliveLog && (keepAliveLog.length / 4)}hrs:`}</div>
            <div className="flex justify-between items-center">
              {keepAliveLog && keepAliveLog.reverse().map((uptime, index) => (
                <>
                  <div key={uptime.id} className="flex items-center tooltip-trigger">
                    <span className="relative flex h-3 w-3">
                      {(index === 0 || (index === keepAliveLog.length - 1)) && <span style={{ marginLeft: '-60%' }} className="text-gray-400 text-nowrap absolute text-xs top-5">{moment(uptime.Datetime.seconds * 1000).format('hh:mm')}</span>}
                      <span className={`relative inline-flex rounded-full h-3 w-3 hover:scale-125 ease-linear  ${uptime.status === "online" ? 'bg-green-500' : 'bg-red-500'}`}></span>
                    </span>
                    <div className="tooltip absolute">
                      <div className="font-bold mb-1">{moment(uptime.Datetime.seconds * 1000).format('LT')}</div>
                      <div>VoltAC: {uptime.VoltAC}</div>
                      <div>Batería: {uptime.Bateria?.toFixed(2)}</div>
                      {uptime.Rssi && (<div>RSSI: {uptime.Rssi}</div>)}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

          {keepAliveDailyLog && (
            <div className="px-4 mb-10">
              <div className="mb-6 text-gray-400 font-bold">{`Uptime últimos ${keepAliveDailyLog.length} días:`}</div>
              <div className="flex justify-end items-center">
                {keepAliveDailyLog.map((uptime, index) => (
                  <>
                    <div key={uptime.id} className="flex items-center ml-1 tooltip-trigger">
                      {/* <span className="text-gray-400 text-sm">{uptime.Date}</span> */}
                      <span className="relative flex h-3 w-3">
                        {(index === keepAliveDailyLog.length - 1) && <span style={{ marginLeft: '-70%' }} className="text-gray-400 text-nowrap absolute text-xs top-5">{moment(uptime.Datetime.seconds * 1000).format('DD/MM')}</span>}
                        <span className={`relative inline-flex rounded-full h-3 w-3 hover:scale-125 ease-linear ${colorByPercentaje(uptime.PercentageOnline)}`}></span>
                      </span>
                      <div className="tooltip absolute">
                        <div className="font-bold mb-1">{moment(uptime.Datetime.seconds * 1000).format('DD/MMMM/YYYY')}</div>
                        <div className="font-bold mb-1">{`${uptime.PercentageOnline.toFixed(1)}% del día online.`}</div>
                      </div>
                    </div>
                  </>
                )).reverse()}
              </div>
            </div>
          )}

          <div className="px-4">
            <div className="mb-2 text-gray-400 font-bold">Últimos eventos:</div>
            {modalLoading ? (
              <div className="flex justify-center items-center py-20"><Loading /></div>
            ) : zoneEvents && zoneEvents.map(event => (
              <div key={event.id} className="py-2 border-b border-gray-100 flex justify-between">
                <span className="text-sm font-bold text-gray-600">{event.Tipo}</span>
                <span className="text-gray-400 text-sm">{event.Date}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  )
}
