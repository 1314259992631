import React from "react";
import Signin from "./components/Signin";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContextProvider, UserAuth } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Facilities from "./components/Facilities";
import Facility from "./components/Facility";
import AdminsHome from "./components/InternalDashboard/AdminsHome";
import NavigateUserFacility from "./components/NavigateUserFacility";

function LoginProtection({ children }) {
  const { user } = UserAuth();

  if (user) return <Navigate to="/account" />;

  return children;
}

function App() {

  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginProtection>
                <Signin />
              </LoginProtection>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <NavigateUserFacility>
                  <AdminsHome />
                </NavigateUserFacility>
              </ProtectedRoute>
            }
          />
          <Route
            path="/facilities"
            element={
              <ProtectedRoute>
                <Facilities />
              </ProtectedRoute>
            }
          />
          <Route
            path="/facilities/:id"
            element={
              <ProtectedRoute>
                <Facility />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
