import React from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import moment from "moment";
import { isOnline } from "../helpers";

export default function Zone({ zone }) {
  const [zoneStatus, setZoneStatus] = React.useState(null)
  const isZoneOnline = zoneStatus?.Datetime && isOnline(zoneStatus?.Datetime.seconds)
  const lastLeepAlive = zoneStatus?.Datetime && moment(zoneStatus?.Datetime.seconds * 1000).format('DD/MM/YY LT')

  React.useEffect(() => {
    async function retrieveZoneStatus() {
      const zoneStatusRef = doc(db, `KA`, zone.id);

      const docSnap = await getDoc(zoneStatusRef);
      if (docSnap.exists()) {
        setZoneStatus(docSnap.data());
      }
    }

    if (zoneStatus === null) {
      retrieveZoneStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    async function retrieveZoneStatus() {
      const zoneStatusRef = doc(db, `KACopec`, zone.id);

      const docSnap = await getDoc(zoneStatusRef);
      if (docSnap.exists()) {
        setZoneStatus(docSnap.data());
      }

    }
    if (zoneStatus === null) {
      retrieveZoneStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    async function retrieveZoneStatus() {
      const zoneStatusRef = doc(db, `KA2`, zone.id);

      const docSnap = await getDoc(zoneStatusRef);
      if (docSnap.exists()) {
        setZoneStatus(docSnap.data());
      }

    }
    if (zoneStatus === null) {
      retrieveZoneStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    async function retrieveZoneStatus() {
      const zoneStatusRef = doc(db, `KA3`, zone.id);

      const docSnap = await getDoc(zoneStatusRef);
      if (docSnap.exists()) {
        setZoneStatus(docSnap.data());
      }

    }
    if (zoneStatus === null) {
      retrieveZoneStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="px-4 flex py-3 justify-between border-b border-gray-200 cursor-pointer hover:bg-gray-50">
      <div>
        <div className="flex items-center mb-2">
          <div className=" text-gray-300 font-light mr-1">{zone.id}</div>
          <div className="text-gray-600 font-bold">{zone.zoneName}</div>
          {lastLeepAlive && (<div className="ml-2 text-gray-400 text-xs">{`Último KA ${lastLeepAlive}`}</div>)}
        </div>
        {zoneStatus && (
          <div className="flex items-center">
            <div className="flex mr-4">
              <span className={zoneStatus.VoltAC === "ON" ? "text-yellow-500" : "text-gray-300"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                  <path d="M11.983 1.907a.75.75 0 0 0-1.292-.657l-8.5 9.5A.75.75 0 0 0 2.75 12h6.572l-1.305 6.093a.75.75 0 0 0 1.292.657l8.5-9.5A.75.75 0 0 0 17.25 8h-6.572l1.305-6.093Z" />
                </svg>
              </span>
              <span className="font-medium ml-1 text-xs text-gray-500">Voltage AC</span>
            </div>
            <div className="flex mr-4 items-center">
              <span className={zoneStatus.Baliza === "ON" ? 'text-red-500' : 'text-gray-300'}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                  <path d="M12 .75a8.25 8.25 0 0 0-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 0 0 .577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 0 1-.937-.171.75.75 0 1 1 .374-1.453 5.261 5.261 0 0 0 2.626 0 .75.75 0 1 1 .374 1.452 6.712 6.712 0 0 1-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 0 0 .577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0 0 12 .75Z" />
                  <path fillRule="evenodd" d="M9.013 19.9a.75.75 0 0 1 .877-.597 11.319 11.319 0 0 0 4.22 0 .75.75 0 1 1 .28 1.473 12.819 12.819 0 0 1-4.78 0 .75.75 0 0 1-.597-.876ZM9.754 22.344a.75.75 0 0 1 .824-.668 13.682 13.682 0 0 0 2.844 0 .75.75 0 1 1 .156 1.492 15.156 15.156 0 0 1-3.156 0 .75.75 0 0 1-.668-.824Z" clipRule="evenodd" />
                </svg>
              </span>
              <span className="font-medium ml-1 text-xs text-gray-500">Baliza</span>
            </div>
            <div className="text-gray-400 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path d="M4.75 8a.75.75 0 0 0-.75.75v2.5c0 .414.336.75.75.75H9.5a.75.75 0 0 0 .75-.75v-2.5A.75.75 0 0 0 9.5 8H4.75Z" />
                <path fillRule="evenodd" d="M3.25 5A2.25 2.25 0 0 0 1 7.25v5.5A2.25 2.25 0 0 0 3.25 15h12.5A2.25 2.25 0 0 0 18 12.75v-1.085a1.5 1.5 0 0 0 1-1.415v-.5a1.5 1.5 0 0 0-1-1.415V7.25A2.25 2.25 0 0 0 15.75 5H3.25ZM2.5 7.25a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-.75.75H3.25a.75.75 0 0 1-.75-.75v-5.5Z" clipRule="evenodd" />
              </svg>
              <span className="font-medium ml-1 text-xs text-gray-500">{`${zoneStatus.Bateria?.toFixed(2)} V en batería`}</span>
            </div>
          </div>
        )}
      </div>

      {isZoneOnline !== undefined && (
        <div className="flex items-center">
          <span className={`text-sm mr-2 font-bold ${isZoneOnline ? 'text-green-500' : 'text-red-500'}`}>{isZoneOnline ? "Up" : "Down"}</span>
          <span className="relative flex h-3 w-3">
            <span className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${isZoneOnline ? 'bg-green-400' : 'bg-red-400'}`}></span>
            <span className={`relative inline-flex rounded-full h-3 w-3 ${isZoneOnline ? 'bg-green-500' : 'bg-red-500'}`}></span>
          </span>
        </div>
      )}

    </div>
  )
}
