export function getTime(seconds) {
  if (seconds < 60) {
    return `${seconds} s`;
  } else if (seconds < 3600) {
    return `${parseInt(seconds / 60)} m`;
  } else if (seconds < 86400) {
    return `${parseInt(seconds / 3600)} h`;
  } else {
    return `${parseInt(seconds / 86400)} d`;
  }
}

export const reasonsForResolutionList = [
  { label: "Simulacro", value: "exercise" },
  { label: "Exitoso", value: "resolved" },
  { label: "Falsa alarma", value: "false_alarm" },
]

export const fullReasonsForResolutionList = [
  { label: "Simulacro", value: "exercise" },
  { label: "Exitoso", value: "resolved" },
  { label: "Falsa alarma", value: "false_alarm" },
  { label: "No gestionada", value: "resolved_automatically" }
]

export const ALARM_LIMIT_IN_SEC = 900;

export function isOnline(date, minutes = 20) {
  const lastEventDate = new Date(date * 1000);
  const currentDate = new Date();
  const currentDateInSeconds = currentDate && Math.floor(currentDate.getTime() / 1000);
  const lastEventDateInSeconds = lastEventDate && Math.floor(lastEventDate.getTime() / 1000);
  const timeThreshold = minutes * 60;
  const sendingSignal = (currentDateInSeconds - lastEventDateInSeconds) < timeThreshold;

  return sendingSignal
}
