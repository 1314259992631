import React, { useState, useEffect, useCallback } from 'react';

export default function Clock() {
  const [time, setTime] = useState('');

  const getFormattedTime = useCallback(() => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  }, []);

  useEffect(() => {
    const updateClock = () => {
      setTime(getFormattedTime());
    };

    const intervalId = setInterval(updateClock, 60000); // 60000 ms = 1 minute

    // Set the initial time immediately on mount
    updateClock();

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [getFormattedTime]);

  return (
    <div className='text-xs mr-2 text-gray-400'>{time}</div>
  );
};
