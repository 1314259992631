import React from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import ZonesByFacility from "./ZonesByFacility";

import { } from 'firebase/firestore'; // Import firestore module
export default function CompanyOverview({ companyName, selectZone }) {
  const [facilities, setFacilities] = React.useState(null)

  React.useEffect(() => {
    async function retrieveFacilities() {
      const facilitiesRef = collection(db, `Facilities`);
      const q = query(facilitiesRef, where("company", "==", companyName));

      onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map(d => ({ ...d.data(), id: d.id }))
        setFacilities(docs)
      })
    }
    if (facilities === null) {
      retrieveFacilities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRestartWebApp = async (id) => {

    try {
      await addDoc(collection(db, "WebAppRestarts"), {
        Datetime: Timestamp.fromDate(new Date()),
        FacilityID: id,
      });
      alert('Successfully restarted!');
    } catch (error) {
      console.error('Error creating document: ', error);
    }
  };

  return (
    <div className="bg-white mb-4 py-2 rounded-xl shadow-sm">
      <div className="text-xl mb-2 uppercase text-gray-400 py-2 px-4 font-black">
        {companyName}
      </div>

      <div>
        {facilities && facilities.map(f => (
          <div key={f.id} className="mb-6">
            <div className="flex items-center justify-between px-4 mb-2">
              <div className="text-xl font-bold text-blue-400 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                </svg>
                {f.title}
              </div>

              <div className="flex items-center">
                <button
                  type="button"
                  className="mr-4 btn-outlined text-xs"
                  onClick={() => onRestartWebApp(f.id)}
                >
                  <span className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    <span>Restart web app</span>
                  </span>
                </button>
                <Link
                  className="text-blue-400 flex items-center text-sm hover:text-blue-600"
                  to={`/facilities/${f.id}`}
                >
                  Ir a alarmas
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </Link>
              </div>
            </div>
            <ZonesByFacility facility={f} selectZone={selectZone} />
          </div>
        ))}
      </div>
    </div>
  )
}
